import React from 'react';
import _ from 'lodash';

const buttonAddCardCurrency = (props) => {
  let {
    currencyData,
    currencyDataAll,
    buttonMode,
    changeButtonAddMode,
    selectCurrency,
    addCardCurrency
  } = props;

  // eliminate currency already shown in list
  let filteredCurrencyDataAll = currencyDataAll && _.omit(currencyDataAll, Object.keys(currencyData));

  return (
    buttonMode === 'initial' ?
      <button id="btnAddCurrency" className="btn btn-info btn-block" type="button" title="Add" onClick={() => changeButtonAddMode('input')}><i className="fas fa-plus"></i> Add {!_.isEmpty(currencyData) ? 'More' : ''} Currency</button>
    :
      <div className="input-group">
        <select name="selectCurrency" className="form-control" onChange={event => selectCurrency(event)}>
          <option>Select...</option>
          {
            filteredCurrencyDataAll && Object.keys(filteredCurrencyDataAll).map(key => {
              return (
                <option key={key} value={key}>{key}</option>
              );
            })
          }
        </select>
        <span className="input-group-btn">
          <button id="btnCancelCurrency" className="btn btn-default" type="button" title="Cancel" onClick={() => changeButtonAddMode('initial')}><i className="fas fa-times"></i></button>
          <button id="btnSubmitCurrency" className="btn btn-success" type="button" title="Submit" onClick={() => addCardCurrency()}><i className="fas fa-check"></i></button>
        </span>
      </div>
  );
}

export default buttonAddCardCurrency;