import React from 'react';

import css from './CardCurrency.css';

const cardCurrency = (props) => {
  let {
    targetCurrency,
    targetCurrencyDescription,
    targetValue,
    currentExchangeRate,
    baseCurrency,
    deleteCardCurrency
  } = props;

  return (
    <div className={`row ${css.CardCurrencyWrapper}`}>
      <div className={`col-xs-10 ${css.leftSection}`}>
        <h4>
          <span>{targetCurrency}</span>
          <span className="pull-right">{targetValue}</span>
        </h4>
        <p>
          <b><i><span>{targetCurrency}</span> - <span className="text-capitalize">{targetCurrencyDescription}</span></i></b>
        </p>
        <p>
          <i>1 <span>{baseCurrency}</span> = <span>{targetCurrency}</span> {currentExchangeRate}</i>
        </p>
      </div>
      <div className="col-xs-2 text-center">
        <button id={`btnDeleteCurrency-${targetCurrency}`} type="button" className="btn btn-danger btn-xs" title="Delete" onClick={() => deleteCardCurrency(targetCurrency)}><i className="fas fa-minus"></i></button>
      </div>
    </div>
  );
}

export default cardCurrency;