import React from 'react';

import Aux from '../../../utils/hoc/Auxiliary';

const inputCurrency = (props) => {
  let {
    baseCurrency,
    baseCurrencyDescription,
    baseValue,
    changeBaseValue
  } = props;

  return (
    <Aux>
      <label><i><span>{baseCurrency}</span> - <span className="text-capitalize">{baseCurrencyDescription}</span></i></label>
      <div className="input-group">
        <span className="input-group-addon">{baseCurrency}</span>
        <input
          name="amount"
          type="number"
          step=".0001"
          className="form-control text-right"
          defaultValue={baseValue}
          onChange={event => changeBaseValue(event)}
        />
      </div>
    </Aux>
  );
}

export default inputCurrency;