import React, { Component }  from 'react';
import axios from 'axios';
import _ from 'lodash';

import Aux from '../../utils/hoc/Auxiliary';
import InputCurrency from '../../components/Input/InputCurrency/InputCurrency';
import CardCurrency from '../../components/Card/CardCurrency/CardCurrency';
import ButtonAddCardCurrency from '../../components/Button/ButtonCurrency/ButtonAddCardCurrency';
import './Home.css';
import numeral from 'numeral';
import currencyDictionary from '../../assets/currencyDictionary.json';

const CURRENCY_FORMAT = '0,0.0000';
const NOT_FOUND = "N/A";

class Home extends Component {
  state = {
    baseCurrency: "USD",
    baseValue: 10.0000,
    initialCurrencyList: ["IDR", "EUR", "GBP", "SGD"],
    currencyData: null,
    currencyDataAll: null,
    buttonAddMode: "initial",  // or "input"
    selectedCurrency: null
  }

  componentDidMount() {
    // get latest exchange rates
    axios.get(`https://api.exchangeratesapi.io/latest?base=${this.state.baseCurrency}&symbols=${this.state.initialCurrencyList.toString()}`)
      .then(response => {
        this.setState({
          currencyData: response.data.rates
        });
      });
  }

  // get all rates
  getCurrencyDataAll = () => {
    return axios.get(`https://api.exchangeratesapi.io/latest?base=${this.state.baseCurrency}`)
      .then(response => {
        this.setState({
          currencyDataAll: response.data.rates,
          selectedCurrency: response.data.rates[0] || null
        });
      });
  }

  // show currency description instead of abbrevation
  getCurrencyDescription = (currency) => {
    return currencyDictionary.hasOwnProperty(currency) ? currencyDictionary[currency] : NOT_FOUND;
  }

  // adjust value by its appropriate rates
  convertValue = (value, rates) => {
    return value * rates;
  }

  // handle base value changes
  changeBaseValue(event) {
    this.setState({
      baseValue: event.target.value
    });
  }
  
  // handle delete card currency
  deleteCardCurrency(key) {
    let currencyData = this.state.currencyData;

    if (currencyData.hasOwnProperty(key)) {
      delete currencyData[key];
      this.setState({currencyData});
    }
  }

  // change wheter it is initial / input mode
  changeButtonAddMode(mode) {
    if (mode === 'input') {
      this.getCurrencyDataAll();
    }

    this.setState({
      buttonAddMode: mode
    });
  }

  // when select a currency from dropdown
  selectCurrency(event) {
    this.setState({
      selectedCurrency: event.target.value
    });
  }

  // handle add card currency
  addCardCurrency() {
    // get the rates too
    let selectedCurrency = _.pick(this.state.currencyDataAll, [this.state.selectedCurrency]);

    // set button add mode to initial
    this.setState({
      currencyData: Object.assign(this.state.currencyData, selectedCurrency),
      buttonAddMode: "initial"
    });
  }

  render() {
    let currencyData = this.state.currencyData;
    
    return (
      <Aux>
        
        {/* input currency section */}
        <div className={`row InputCurrencyWrapper`}>
          <div className="col-xs-12">
            <InputCurrency
              baseCurrency={this.state.baseCurrency}
              baseCurrencyDescription={this.getCurrencyDescription(this.state.baseCurrency)}
              baseValue={this.state.baseValue}
              changeBaseValue={event => this.changeBaseValue(event)}
            />
          </div>
        </div>

        {/* currency list section */}
        <div className={`row CardCurrencyWrapper`}>
          <div className="col-xs-12">
            <ul className="list-group">
              {
                // make sure currencyData is not null
                currencyData && Object.keys(currencyData).map(key => {
                  return (
                    <li key={key} className={`list-group-item CardCurrencyList`}>
                      <CardCurrency
                        targetCurrency={key}
                        targetCurrencyDescription={this.getCurrencyDescription(key)}
                        targetValue={numeral(this.convertValue(this.state.baseValue, currencyData[key])).format(CURRENCY_FORMAT)}
                        currentExchangeRate={numeral(currencyData[key]).format(CURRENCY_FORMAT)}
                        baseCurrency={this.state.baseCurrency}
                        deleteCardCurrency={key => this.deleteCardCurrency(key)}
                      />
                    </li>
                  );
                })
              }
            </ul>
          </div>
        </div>

        {/* add more currency */}
        <div className={`row ButtonAddCurrencyWrapper`}>
          <div className="col-xs-12">
            <ButtonAddCardCurrency
              currencyData={this.state.currencyData}
              currencyDataAll={this.state.currencyDataAll}
              buttonMode={this.state.buttonAddMode}
              changeButtonAddMode={mode => this.changeButtonAddMode(mode)}
              selectCurrency={event => this.selectCurrency(event)}
              addCardCurrency={() => this.addCardCurrency()}
            />
          </div>
        </div>

      </Aux>
    )
  }
}

export default Home;