import React from 'react';
import Aux from '../../utils/hoc/Auxiliary';

const layout = (props) => (
  <Aux>
    <div className="container-fluid">
      {props.children}
    </div>
  </Aux>
);

export default layout;